<template>
    <div class="mt-2">
        <div>
            <h2 class="text-xl font-semibold text-center">Venta de bazar</h2>
        </div>
        
        <div class="grid grid-cols-3 text-sm my-2">
            <div class="text-left">
                Nº venta:
            </div>
            <div class="text-right col-span-2">
                {{ datosBazar.numeroVenta }}
            </div>
            <div class="text-left">
                Fecha:
            </div>
            <div class="text-right col-span-2">
                {{ datosBazar.fecha }}
            </div>
            <div class="text-left">
                Hora:
            </div>
            <div class="text-right col-span-2">
                {{ datosBazar.hora }}
            </div>
            <div class="text-left">
                Local:
            </div>
            <div class="text-right col-span-2">
                {{ datosBazar.sucursal }}
            </div>
        </div>
        <div class="grid grid-cols-2 my-2">
            <div class="text-left col-span-2 text-base">
                Detalle venta
            </div>
            <template v-for="(articulo,indice) in datosBazar.articulos" :key="indice">
                <div class="text-left text-lg col-span-2 font-semibold">
                    {{ articulo.nombre }}
                </div>
                <div class="text-left text-sm">
                    Cantidad:
                </div>
                <div class="text-right text-sm">
                    {{ articulo.cantidad }}
                </div>
                <div class="text-left text-sm">
                    Precio:
                </div>
                <div class="text-right text-sm">
                    {{ this.formatearCifra(articulo.precio) }}
                </div>
                <div class="text-left text-sm">
                    Subtotal:
                </div>
                <div class="text-right text-sm">
                    {{ this.formatearCifra(articulo.total) }}
                </div>
            </template>
            <div class="text-left text-xl font-semibold uppercase">
                Total:
            </div>
            <div class="text-right text-xl font-semibold">
                {{ `$ ${this.formatearCifra(datosBazar.total)}` }}
            </div>
        </div>
        <div class="grid grid-cols-2 mb-2 mt-4">
            <div class="text-left text-sm">
                Monto pago:
            </div>
            <div class="text-right text-sm">
                {{ `$ ${this.formatearCifra(datosBazar.montoPago)}` }}
            </div>
            <div class="text-left text-sm">
                Medio de pago:
            </div>
            <div class="text-right text-sm">
                {{ this.datosBazar.medioPago }}
            </div>
        </div>
    </div>
</template>

<script>
import { separarPorMiles } from "@/utils/helpers";

export default {
    methods: {
        formatearCifra(cifra) {
            return separarPorMiles(cifra);
        },
    },
    props: {
        datosBazar: {
            type: Object,
            default: null
        }
    }
}
</script>